<template>
  <div class="content-page">
    <!-- 导航栏 -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active"><router-link to="/webstore">Webstore</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">
          <router-link to="/webstoreallgoods">All Products</router-link>
        </li>
        <li class="breadcrumb-item" aria-current="page">Product Page</li>
      </ol>
    </nav>
    <!-- 商品详情 -->
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-3 text-center">
            <img
              class="w-75 mb-2"
              v-for="item of 4"
              :key="item"
              src="../../../assets/images/user/01.jpg"
              alt=""
            />
            <div class="text-center"><i class="ri-arrow-down-s-line"></i></div>
          </div>
          <div class="col-9">
            <img
              class="w-100"
              src="../../../assets/images/user/01.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="b-border p-2">
          <h3>Meiji Chocolate Cup</h3>
          <div class="row">
            <div class="col-10">
              <div class="d-flex pt-3 flex-nowrap">
                <i class="star-act ri-star-s-fill"></i>
                <i class="star-act ri-star-s-fill"></i>
                <i class="star-act ri-star-s-fill"></i>
                <i class="star-act ri-star-s-fill"></i>
                <i class="star-act ri-star-s-line"></i>
                <strong> 4.8</strong> (12Comments)
              </div>
            </div>
            <div class="col-2 text-right"><a href="javascript:;">Share</a></div>
          </div>
        </div>
        <div class="b-border p-2">
          <div class="row">
            <p class="col-3 title p-2">Price</p>
            <p class="col-9 price">$75</p>
          </div>

          <div class="row pb-3">
            <div class="col-3 title">Freight</div>
            <div class="col-2 p-0">Ship to</div>
            <div class="col-3 p-0">
              <select class="form-control p-0">
                <option value="" disabled selected style="display: none">
                  Hongkong
                </option>
                <option>大陆</option>
                <option>海外</option>
              </select>
            </div>
            <div class="col-1 p-0 text-right">$10.00</div>
            <div class="col-3 p-0 text-right">
              <input type="checkbox" /> Pick up
            </div>
          </div>
        </div>

        <div class="mt-3 p-2">
          <div class="row">
            <p class="col-3 title">Quantity</p>
            <p class="col-9">
              <button class="btn1" @click="minus">-</button>
              <input class="num" type="text" :value="number" />
              <button class="btn1" @click="add">+</button>
            </p>
          </div>
          <div class="row">
            <p class="col-3 title">Size</p>
            <div class="col-9">
              <button type="button" class="btn1 btn btn-primary">S</button>
              <button type="button" class="btn1 btn">M</button>
              <button type="button" class="btn1 btn disabled">L</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 p-3">
              <button class="btn2 active-btn">Buy Now</button>
            </div>
            <div class="col-md-5 p-3">
              <button class="btn2">Add to Cart</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 下半区域 -->
    <div>
      <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            >Recommend Details</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            >Comments</a
          >
        </li>
      </ul>
      <div class="tab-content mt-3 p-2" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="row mb-4">
            <!-- 左边选择区 -->
            <div class="col-md-3 col-sm-12 pr-lg-2">
              <b-card class="mt-3">
                <h4 class="pb-4">Filters</h4>

                <form>
                  <h6>Category</h6>
                  <p><input type="checkbox" /> Praise</p>
                  <p><input type="checkbox" /> negative</p>
                  <p><input type="checkbox" /> Contains pictures</p>
                </form>
                <hr />
                <form>
                  <h6>Brands</h6>
                  <p><input type="checkbox" /> S</p>
                  <p><input type="checkbox" /> M</p>
                  <p><input type="checkbox" /> L</p>
                </form>
                <button class="w-100 f-btn">Filter</button>
              </b-card>
            </div>
            <!-- 右边评论区 -->
            <div class="col-md-9 col-sm-12 pl-lg-3">
              <b-card class="mt-3">
                <div class="row b-border mt-2" v-for="item of 4" :key="item">
                  <div class="col-1">
                    <img
                      src="../../../assets/images/user/11.jpg"
                      alt=""
                      class="p-1 rounded-circle avatar-60"
                    />
                  </div>
                  <div class="col-8 pl-4">
                    <h4>Sunny</h4>
                    <div class="d-flex flex-nowrap">
                      <i class="star-act ri-star-s-fill"></i>
                      <i class="star-act ri-star-s-fill"></i>
                      <i class="star-act ri-star-s-fill"></i>
                      <i class="star-act ri-star-s-fill"></i>
                      <i class="star-act ri-star-s-line"></i>
                    </div>
                    <p class="mb-2">Great. I learned a lot</p>
                  </div>
                  <div class="col-3 text-center">
                    <p>16/08/2020</p>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          2223333
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      number: 1,
      value: null,
    };
  },
  methods: {
    add () {
      this.number++;
    },
    minus () {
      if (this.number > 1) {
        this.number--;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.num {
  width: 30px;
  height: 30px;
  text-align: center;
  border: 1px solid #0aa1eb;
  color: #0aa1eb;
  margin: 2px;
}
.star-act {
  color: #ffba68;
}
.form-control {
  width: 95px;
  height: 25px;
  border: 0 !important;
  color: #999999;
}
.btn {
  line-height: 1;
}
.btn1 {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 2px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.title {
  font-size: 14px;
  font-family: "poppins", "montserra";
  font-weight: 400;
  text-align: left;
  color: #999999;
}
.price {
  font-size: 24px;
  font-family: Poppins Medium, Poppins Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #ef5959;
}
.btn2 {
  width: 160px;
  height: 45px;
  color: #0aa1eb;
  border: 1px solid #0aa1eb;
  background: #f6f8fa;
}
h6 {
  color: #666666;
  padding-bottom: 13px;
}
.active-btn {
  color: #fff;
  background: #0aa1eb;
}
.int {
  width: 100px;
  height: 35px;
  background: #f3f4f5;
  border: 1px solid #f3f4f5;
  border-radius: 5px;
  text-align: center;
}
.f-btn {
  width: 225px;
  height: 35px;
  color: #0aa1eb;
  border: 1px solid #0aa1eb;
  background: #f6f8fa;
  border-radius: 5px;
}
.b-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#rating-line {
  height: 30px;
  padding: 0;
  border: 0;
  color: #ffba68;
}
</style>